<template>
  <div class="col-12" style="margin-top: 7%">
    <b-table selectable select-mode="single" ref="pillarPracticeTypeStatusTable"
      style="max-height: 45rem !important; border-top: 8px solid white" sticky-header id="pillarPracticeTypeStatus"
      class="data-table gn-table" striped responsive hover @row-selected="onRowSelected"
      :items="pillarPracticeTypeStatusItems" :fields="pillarPracticeTypeStatusFields">
      <template #cell(statusCode)="row">
        <img alt="more" v-if="row.item.statusCode === 'Reviewed'" class="status-icons"
          src="../../../assets/review-green.svg" />
        <img alt="more" v-else class="status-icons" src="../../../assets/submitted-grey.svg" />
      </template>
    </b-table>
  </div>
</template>
<script>
export default {
  name: "PillarPracticeTypeStatus",
  props: {
    pillarPracticeTypeStatusItemsParent: [],
    indexSelectedParent: "",
  },
  data() {
    return {
      selectedRowPillarPracticeTypeStatus: {},
      pillarPracticeTypeStatusItems: [],
      pillarPracticeTypeStatusFields: [
        {
          key: "pillar_Code",
          sortable: false,
          label: "Pillar",
        },
        {
          key: "practiceNumber",
          sortable: false,
          label: "Practice",
        },
        {
          key: "decisionType",
          sortable: false,
          label: "Type",
        },
        {
          key: "statusCode",
          sortable: false,
          label: "Status",
        },
      ],
    };
  },
  mounted() {
    this.pillarPracticeTypeStatusItems =
      this.pillarPracticeTypeStatusItemsParent;
    setTimeout(() => {
      if (this.$refs && this.$refs.pillarPracticeTypeStatusTable) {
        this.selectThirdRow(this.indexSelectedParent);
      }
    }, 1000);
  },

  components: {},
  methods: {
    onRowSelected(items) {
      this.selectedRowPillarPracticeTypeStatus = items[0];
      this.$emit("rowClickedParent", items[0]);
    },
    selectThirdRow(index) {
      // Rows are indexed from 0, so the third row is index 2
      if (this.$refs && this.$refs.pillarPracticeTypeStatusTable) {
        if (index) {
          this.$refs.pillarPracticeTypeStatusTable.selectRow(parseInt(index));
        } else {
          this.$refs.pillarPracticeTypeStatusTable.selectRow(0);
        }
      }
    },
  },
};
</script>
<style scoped>
.status-icons {
  margin: 0px 5px;
  width: 1.66rem;
  height: 1.66rem;
}
</style>