<template>
  <div class="col-12">
    <div class="row">
      <label style="margin: 1rem; float: right;font-size: 1.5rem;"
        class="col-2 page-heading-dropdown">Proposed</label><b-button style="margin: 1rem; float: right" pill
        variant="outline-secondary" class="col-3 mark-btn " disabled>Mark as reviewed</b-button>
      <b-button style="border:none" @click="discard" pill variant="outline-secondary"
        class="col-2 discard-btn">Discard</b-button>
      <b-button style="margin: 1rem; float: right" pill variant="outline-secondary" class="col-2 submit-btn "
        @click="save">Save</b-button>
    </div>
    <div class="gray-box-inner-row2">
      <div class="recommend-d">
        <label for="pillar" class="form-label select-lable lable-text">Change Type<span class="req">*</span></label>
        <select class="units form-control page-heading-dropdown-content principle-select cust-st-icon" id="recommend"
          v-model="detail.changeType" required>
          <option v-for="change in changeType" v-bind:key="change.typeId" :value="change.typeDescription">
            {{ change.typeDescription }}
          </option>
        </select>
      </div>
    </div>
    <div class="gray-box-inner-row2">
      <label for="descriptionOgReview" class="form-label page-heading-dropdown lable-text1 gray-box-label">
        Description
      </label>
      <textarea class="page-content-regular action-desc select-text" rows="4" cols="105" id="descriptionOgReview"
        v-model="detail.practiceDescription">
      </textarea>
    </div>
    <div class="gray-box-inner-row2">
      <label for="changeTypeOgReview" class="form-label page-heading-dropdown lable-text1 gray-box-label">
        Guideline
      </label>
      <textarea class="page-content-regular action-desc select-text" rows="4" cols="105" id="changeTypeOgReview"
        v-model="detail.practiceGuidelines">
      </textarea>
    </div>
  </div>
</template>
<script>
export default {
  name: "EditProposedRecommendations",
  props: {
    changeType: [],
    prChangeType: "",
    prDescription: "",
    prGuideline: "",
    prPracticeId: "",
    prRequestId: ""
  },
  data() {
    return {
      detail: {}
    };
  },
  mounted() {
    this.detail = {
      changeType: this.prChangeType,
      practiceDescription: this.prDescription,
      practiceGuidelines: this.prGuideline,
      requestId: this.prRequestId,
    }
  },
  components: {},
  methods: {
    save() {
      //console.log("saveClicked edit",this.detail)
      this.$emit("saveClicked", this.detail);
    },
    discard() {
      this.$emit("discardClicked");
    },
  },
};
</script>
<style scoped>
.mark-btn {
  height: 3.3rem;
  padding: 0 1rem;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  background-color: #EFF3FF;
  border: 1px solid #869ED8;
  color: #274BA7;
}

.mark-btn:focus {
  background-color: #EFF3FF;
  border: 1px solid #869ED8;
}

.discard-btn {
  color: #274ba7;
  background: transparent;
  border: transparent;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  padding: 0.375rem 0.75rem;
}

.discard-btn:hover {
  color: #274ba7b4 !important;
  background: transparent !important;
  border: transparent !important;
}

.submit-btn {
  height: 3.3rem;
  padding: 0 1rem;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  background-color: #274ba7;
  color: white;
}

.submit-btn:focus {
  background-color: #274ba7;
}

.gray-box-label {
  background: none !important;
}

.principle-select {
  height: 4.4rem;
  font-family: "BarlowR", sans-serif !important;
  border-color: #eeeeee;
}

.action-desc {
  border: #eeeeee 1px solid;
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
  color: #313131;
  resize: none;
}

.select-text {
  font-size: 1.166rem !important;
  font-family: "BarlowR", sans-serif !important;
}

textarea:focus {
  outline: none !important;
  border-color: #eeeeee;
}

.gray-box-inner-row2 {
  margin-top: 2rem;
}

.lable-text1 {
  position: absolute;
  /* background: rgb(243, 240, 240); */
  background: #e5e5e5;
  margin: -0.8rem 0 0 1rem;
  padding-right: 5px;
  padding-left: 5px;
}
</style>