<template>
  <div class="menu-body" v-if="$oidc.isAuthenticated">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div style="margin-top: -2%; margin-bottom: 4%">
      <b-button style="margin: 1rem; float: right; margin-top: 0.3rem" pill variant="outline-secondary" class="submit-btn"
        @click="createValidationStatusRedirect" :disabled="!createValidationStatus">Create
        Validation</b-button>
      <div class="button-dd" style="margin-top: 0.4%">
        <div class="download-view action-btn">
          <JsonExcel :fields="pillarPracticeTypeStatusFieldsParentReview"
            :data="pillarPracticeTypeStatusItemsParentReview">
            <img alt="download" src="../../../assets/download_icon.svg" />
          </JsonExcel>
        </div>
        <div class="funnel-con">
          <div class="action-btn primary-color" v-b-modal.action-filter>
            <b-icon icon="funnel"></b-icon>
          </div>
        </div>
        <b-modal id="action-filter" ref="my-modal" :hide-header="true" :hide-footer="true" dialog-class="right">
          <ActionItemFilter :hideModal="hideModal" :filterArray="filterArray" :applyFilter="applyFilter"
            :resetFilter="resetFilter" :changedFilter="changedFilter" :filterSelected="filterSelected"
            :onSelect="() => { }" :onRemove="() => { }" />
        </b-modal>
      </div>
    </div>
    <div class="main-con" style="margin-top: 3%">
      <div class="detail-con">
        <div class="col-12 displayFlex">
          <div class="col-3" style="padding-right: 0">
            <div v-if="pillarPracticeTypeStatusItemsParentReview &&
              pillarPracticeTypeStatusItemsParentReview.length > 0
              ">
              <pillarPracticeTypeStatus :indexSelectedParent="indexSelected" :pillarPracticeTypeStatusItemsParent="pillarPracticeTypeStatusItemsParentReview
                " @rowClickedParent="rowClicked" />
            </div>
          </div>
          <div class="col-9 row displayFlex" style="" v-if="pillarPracticeTypeStatusItemsParentReview &&
            pillarPracticeTypeStatusItemsParentReview.length > 0
            ">
            <div class="row col-12 yellowHexColor" style="
                border-bottom: 2px solid #e4e1e1;
                height: 6rem;
                padding-top: 2%;
                padding-bottom: 3.39%;
              ">
              <div class="col-6">
                <div class="col-12" style="display: grid; border-right: 0px solid #e4e1e1">
                  <span class="col-12 row justify-content-end yellowHeader" style="float: right">
                    Total Proposed Practices
                  </span>
                  <span class="col-12 row justify-content-end yellowText" style="float: right; padding-right: 12%">{{
                    totalPractices
                  }}</span>
                </div>
              </div>
              <div class="col-6">
                <div class="col-12 displayFlex">
                  <div class="col-4" style="border-right: 0px solid #e4e1e1">
                    <span class="row yellowHexColor yellowHeader">Major</span>
                    <span class="row yellowHexColor yellowText" style="margin-left: -4%">{{ majorPractices }}</span>
                  </div>
                  <div class="col-4" style="border-right: 0px solid #e4e1e1">
                    <span class="row yellowHexColor yellowHeader">Minor</span>
                    <span class="row yellowHexColor yellowText" style="margin-left: -4%">{{ minorPractices }}</span>
                  </div>
                  <div class="col-4">
                    <span class="row yellowHexColor yellowHeader">Clarity</span>
                    <span class="row yellowHexColor yellowText" style="margin-left: -4%">{{ clarityPractices }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row col-12">
              <div class="col-6" style="border-right: 1px solid rgb(228, 225, 225)">
                <div class="col-12" v-if="checkOg">
                  <OriginalProcess :ogChangeType="ogChangeType" :ogDescription="ogDescription" :ogGuideline="ogGuideline"
                    :ogPracticeId="ogPracticeId" />
                </div>
              </div>
              <div class="col-6">
                <div class="col-12" v-if="checkPp">
                  <div v-if="!isEdit">
                    <ProposedRecommendation :prChangeType="prChangeType" :prDescription="prDescription"
                      :prGuideline="prGuideline" :prPracticeId="prPracticeId" :prRequestId="prRequestId"
                      :prStatusCode="prStatusCode" @editClicked="editClicked" @markReview="markReview" />
                  </div>
                  <div v-if="isEdit">
                    <EditProposedRecommendations :changeType="changeType" :prChangeType="prChangeType"
                      :prDescription="prDescription" :prGuideline="prGuideline" :prPracticeId="prPracticeId"
                      :prRequestId="prRequestId" @saveClicked="saveClicked" @discardClicked="discardClicked" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import PillarPracticeTypeStatus from "./PillarPracticeTypeStatus.vue";
import OriginalProcess from "./OriginalProcess.vue";
import ProposedRecommendation from "./ProposedRecommendation.vue";
import EditProposedRecommendations from "./EditProposedRecommendations.vue";
import Api from "../../../common/constants/api";
import ApiCalls from "@/common/api/apiCalls";
import ActionItemFilter from "@/components/Dashboard/ActionItemFilter.vue";
import JsonExcel from "vue-json-excel";

export default {
  name: "ReviewProgress",
  mixins: [PillarPracticeTypeStatus],
  data() {
    return {
      filterArray: [],
      filterSelected: [],
      isEdit: false,
      showLoader: false,
      pillarPracticeTypeStatusItemsParentReview: [],
      refreshOg: false,
      refreshPp: false,
      ogChangeType: "",
      ogDescription: "",
      ogGuideline: "",
      ogPracticeId: "",
      prChangeType: "",
      prDescription: "",
      prGuideline: "",
      prPracticeId: "",
      prRequestId: "",
      prStatusCode: "Submitted",
      totalPractices: 0,
      minorPractices: 0,
      majorPractices: 0,
      clarityPractices: 0,
      createValidationStatus: true,
      pillarPracticeTypeStatusFieldsParentReview: {
        Pillar: "pillar_Code",
        "Practice Number": "practiceNumber",
        Type: "decisionType",
        Status: "statusCode",
      },
      indexSelected: 0,
    };
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 2,
      subMenu: 4,
    });
    this.$store.dispatch("breadcrumb", {
      title: "Governance Review Process",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        {
          name: "Governance",
          redirect: "/governance",
          primary: true,
        },
        // { name: "Assessment", primary: false },
      ],
    });
  },
  created() {
    this.getchangetypeData();
    this.getViewPracticeStatusData();
  },
  mounted() { },
  components: {
    PillarPracticeTypeStatus,
    Breadcrumb,
    Loader,
    OriginalProcess,
    ProposedRecommendation,
    EditProposedRecommendations,
    ActionItemFilter,
    JsonExcel,
  },
  methods: {
    saveClicked(detail) {
      ApiCalls.httpCallDetails(
        Api.SAVEORIGINALPROPOSED + detail.requestId,
        "put",
        detail
      ).then((data) => {
        if (data.success) {
          this.getOgAndPPDetails();
          this.isEdit = false;
          this.checkEdit();
          let index = this.pillarPracticeTypeStatusItemsParentReview.findIndex(
            (val) => val.requestId === detail.requestId
          );
          this.pillarPracticeTypeStatusItemsParentReview = [];
          //console.log("out",this.indexSelected)
          this.indexSelected = index;
          setTimeout(() => {
            this.getViewPracticeStatusData();
          }, 500);

          setTimeout(() => {
            //console.log("in",this.indexSelected)
            this.selectThirdRow(this.indexSelected);
          }, 5000);
        }
      });
    },
    createValidationStatusRedirect() {
      this.$router.push("/assign-plant-validation");
    },
    getViewPracticeStatusData() {
      ApiCalls.httpCallDetails(Api.GETREVIEWPRACTICESTSTATUSDATA, "get").then(
        (dataJSON) => {
          let arrayPractice = [];
          this.createValidationStatus = true;
          for (const element of dataJSON.data) {
            arrayPractice.push(element);
            this.createValidationStatus =
              this.createValidationStatus &&
              element.createdValidationVersion;
            // }
          }
          setTimeout(() => {
            this.pillarPracticeTypeStatusItemsParentReview = arrayPractice;
            this.pillarPracticeTypeStatusItemsParentReview =
              this.pillarPracticeTypeStatusItemsParentReview.filter(
                (item, pos, self) => {
                  return (
                    pos ===
                    self.findIndex((t) => t.requestId === item.requestId)
                  );
                }
              );

            let statusCode = this.pillarPracticeTypeStatusItemsParentReview.map(
              (value) => value.statusCode
            );
            statusCode = statusCode.filter(function (item, pos) {
              return statusCode.indexOf(item) == pos;
            });

            let type = this.pillarPracticeTypeStatusItemsParentReview.map(
              (value) => value.decisionType
            );
            type = type.filter(function (item, pos) {
              return type.indexOf(item) == pos;
            });

            let pillar_Code =
              this.pillarPracticeTypeStatusItemsParentReview.map(
                (value) => value.pillar_Code
              );
            pillar_Code = pillar_Code.filter(function (item, pos) {
              return pillar_Code.indexOf(item) == pos;
            });
            //practiceNumber
            let practiceNumber =
              this.pillarPracticeTypeStatusItemsParentReview.map(
                (value) => value.practiceNumber
              );
            practiceNumber = practiceNumber.filter(function (item, pos) {
              return practiceNumber.indexOf(item) == pos;
            });
            this.filterArray = [
              {
                labelName: "Status Code",
                arrayValue: statusCode,
                id: "Status",
              },
              {
                labelName: "Type ",
                arrayValue: type,
                id: "Type",
              },
              {
                labelName: "Pillar ",
                arrayValue: pillar_Code,
                id: "Pillar",
              },
              {
                labelName: "Practice Number ",
                arrayValue: practiceNumber,
                id: "Practice",
              },
            ];
            this.filterSelected = this.filterArray;
            this.selectedRowPillarPracticeTypeStatus = dataJSON.data[0];
            this.rowClicked(dataJSON.data[0]);
            this.totalPractices =
              this.pillarPracticeTypeStatusItemsParentReview.length;
            this.majorPractices =
              this.pillarPracticeTypeStatusItemsParentReview.filter(
                (x) => x.decisionType === "Major"
              ).length;
            this.minorPractices =
              this.pillarPracticeTypeStatusItemsParentReview.filter(
                (x) => x.decisionType === "Minor"
              ).length;
            this.clarityPractices =
              this.pillarPracticeTypeStatusItemsParentReview.filter(
                (x) => x.decisionType === "Clarity"
              ).length;
          }, 500);
        }
      );
    },
    changedFilter(event) {
      let filterSelectedC = [];
      this.filterArray.map((val, index) => {
        if (
          val.id === event.target.id &&
          event.target.value !== val.id &&
          val.arrayValue.includes(event.target.value)
        ) {
          let temp = {
            id: val.id,
            arrayValue: [event.target.value],
            labelName: val.labelName,
          };
          filterSelectedC.push(temp);
          return filterSelectedC;
        } else if (
          val.id === event.target.id &&
          val.id === event.target.value
        ) {
          let temp = {
            id: val.id,
            arrayValue: val.arrayValue,
            labelName: val.labelName,
          };
          filterSelectedC.push(temp);
          return filterSelectedC;
        } else if (
          val.id !== event.target.id &&
          event.target.value !== val.id &&
          val.arrayValue.length ===
          this.filterSelected[index]["arrayValue"].length
        ) {
          let temp = {
            id: val.id,
            arrayValue: val.arrayValue,
            labelName: val.labelName,
          };
          filterSelectedC.push(temp);
          return filterSelectedC;
        } else {
          filterSelectedC.push(this.filterSelected[index]);
        }
      });
      this.filterSelected = filterSelectedC;
    },
    applyFilter() {
      let filteredItems = [];
      filteredItems = this.pillarPracticeTypeStatusItemsParentReview;
      let filterSelected = this.filterSelected;
      if (
        filterSelected.length > 0 &&
        filterSelected[0] &&
        ["arrayValue"].length > 0
      ) {
        filteredItems = this.pillarPracticeTypeStatusItemsParentReview.filter(
          ({ statusCode }) =>
            filterSelected[0]["arrayValue"].includes(statusCode)
        );
      }
      if (
        filterSelected.length > 0 &&
        filterSelected[1] &&
        filterSelected[1]["arrayValue"].length > 0
      ) {
        filteredItems = filteredItems.filter(({ decisionType }) =>
          filterSelected[1]["arrayValue"].includes(decisionType)
        );
      }

      if (
        filterSelected.length > 0 &&
        filterSelected[2] &&
        filterSelected[2]["arrayValue"].length > 0
      ) {
        filteredItems = filteredItems.filter(({ pillar_Code }) =>
          filterSelected[2]["arrayValue"].includes(pillar_Code)
        );
      }

      if (
        filterSelected.length > 0 &&
        filterSelected[3] &&
        filterSelected[3]["arrayValue"].length > 0
      ) {
        filteredItems = filteredItems.filter(({ practiceNumber }) =>
          filterSelected[3]["arrayValue"].includes(practiceNumber)
        );
      }
      this.pillarPracticeTypeStatusItemsParentReview = [];
      setTimeout(() => {
        this.pillarPracticeTypeStatusItemsParentReview = filteredItems;
        this.hideModal();
      }, 500);
    },
    resetFilter() {
      this.pillarPracticeTypeStatusItemsParentReview = [];
      setTimeout(() => {
        this.getViewPracticeStatusData();
        this.hideModal();
      }, 500);
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    markReview(id) {
      ApiCalls.httpCallDetails(
        Api.MARKASREVIEWED + this.prRequestId,
        "put",
        null
      ).then((dataJSON) => {
        let index = this.pillarPracticeTypeStatusItemsParentReview.findIndex(
          (val) => val.requestId === this.prRequestId
        );
        if (index !== -1) {
          this.pillarPracticeTypeStatusItemsParentReview[index].statusCode =
            "Reviewed";

          this.pillarPracticeTypeStatusItemsParentReview = [];
        }
        setTimeout(() => {
          this.indexSelected = 0;
          this.getViewPracticeStatusData();
        }, 500);
      });
    },
    getchangetypeData() {
      ApiCalls.httpCallDetails(Api.GETCHANGETYPEDROPDOWN, "get").then(
        (data) => {
          this.changeType = data.data;
        }
      );
    },
    checkOg() {
      return (this.refreshOg === true)
    },
    checkPp() {
      return (this.refreshPp === true)
    },
    rowClicked(items) {
      this.selectedRowPillarPracticeTypeStatus = items;
      console.log(items);
      this.prStatusCode = items.statusCode;
      this.getOgAndPPDetails();
      this.prStatusCode = items.statusCode;
      //console.log(items,this.selectedRowPillarPracticeTypeStatus)
    },
    checkEdit() {
      return (this.isEdit === true)
    },
    editClicked() {
      this.isEdit = true;
      this.checkEdit();
    },

    discardClicked() {
      this.isEdit = false;
      this.checkEdit();
    },
    getOgAndPPDetails() {
      let index = this.pillarPracticeTypeStatusItemsParentReview.findIndex(
        (val) => val.requestId === this.prRequestId
      );
      if (index !== -1) {
        this.prStatusCode =
          this.pillarPracticeTypeStatusItemsParentReview[index].statusCode;
      }
      ApiCalls.httpCallDetails(
        Api.GETOGANDPPDETAILS +
        this.selectedRowPillarPracticeTypeStatus.requestId +
        "/" +
        this.selectedRowPillarPracticeTypeStatus.practiceId,
        "get"
      ).then((dataJSON) => {
        if (dataJSON.data) {
          if (dataJSON.data.originalPractice) {
            let og = dataJSON.data.originalPractice;
            this.ogChangeType = og.changeType;
            this.ogDescription = og.practiceDescription;
            this.ogGuideline = og.practiceGuidelines;
            this.ogPracticeId = og.practiceId;
            this.refreshOg = true;
            this.checkOg();
          } else {
            this.ogChangeType = "";
            this.ogDescription = "";
            this.ogGuideline = "";
            this.ogPracticeId = "";
            this.refreshOg = true;
          }
          if (dataJSON.data.proposedPractice) {
            let og = dataJSON.data.proposedPractice;
            this.prChangeType = og.changeType;
            this.prDescription = og.practiceDescription;
            this.prGuideline = og.practiceGuidelines;
            this.prPracticeId = og.practiceId;
            this.prRequestId = og.requestId;
            this.refreshPp = true;
          } else {
            this.prChangeType = "";
            this.prDescription = "";
            this.prGuideline = "";
            this.prPracticeId = "";
            this.prRequestId = "";
            this.refreshPp = true;
            this.checkPp();
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.displayFlex {
  display: inline-flex;
}

.yellowHexColor {
  background-color: #fefce5;
}

.button-dd {
  display: inline-flex;
  float: right;
}

.funnel-con {
  font-size: 1.33rem;
}

.download-view {
  border-radius: 2rem;
  border: 1px solid #274ba7;
  margin-left: 1rem;
  font-size: 1.33rem;
  margin-right: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.download-view:hover {
  background: #e3e8f7;
}

.action-btn {
  border: 1px #274ba7 solid;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  cursor: pointer;
}

.yellowHeader {
  font-size: 1.0833333333333333rem;
  font-family: "BarlowM", sans-serif;
}

.yellowText {
  font-size: 1.5833333333333333rem;
  font-family: "BarlowSb", sans-serif;
}

.submit-btn {
  height: 3.3rem;
  padding: 0 1rem;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  background-color: #274ba7;
  color: white;
}

.submit-btn:focus {
  background-color: #274ba7;
}
</style>