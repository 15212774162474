<template>
  <div class="col-12">
    <div class="row">
      <label style="margin: 1rem; float: righ;font-size: 1.5rem;"
        class="col-3 page-heading-dropdown">Proposed</label><b-button
        style="margin: 1rem;margin-top: 0.5rem;float: right" pill variant="outline-secondary" class="col-3 submit-btn"
        @click="markedAsReviewed" :disabled="prStatusCode!=='Submitted'">Mark as reviewed</b-button>
      <b-button style="margin: 1rem;margin-top: 0.5rem; float: right" pill variant="outline-secondary"
        class="col-3 submit-btn" @click="edit" :disabled="prStatusCode !== 'Submitted'">Edit Practice</b-button>
    </div>
    <div class="gray-box-inner-row2">
      <label for="changeTypeOgReview" class="form-label page-heading-dropdown lable-text1 gray-box-label">
        Change Type
      </label>
      <b-input class="
          inputMarginTop
          form-control
          page-content-regular
          principle-select
          select-text
        " id="changeTypeOgReview" v-model="prChangeType" disabled>
      </b-input>
    </div>
    <div class="gray-box-inner-row2">
      <label for="descriptionOgReview" class="form-label page-heading-dropdown lable-text1 gray-box-label">
        Description
      </label>
      <textarea class="page-content-regular action-desc select-text" rows="4" cols="105" id="descriptionOgReview"
        v-model="prDescription" disabled>
      </textarea>
    </div>
    <div class="gray-box-inner-row2">
      <label for="changeTypeOgReview" class="form-label page-heading-dropdown lable-text1 gray-box-label">
        Guideline
      </label>
      <textarea class="page-content-regular action-desc select-text" rows="4" cols="105" id="changeTypeOgReview"
        v-model="prGuideline" disabled>
      </textarea>
    </div>
  </div>
</template>
<script>

export default {
  name: "ProposedRecommendation",
  props: { prChangeType: "", prDescription: "", prGuideline: "", prPracticeId: "", prRequestId: "", prStatusCode: "" },
  data() {
    return {};
  },
  mounted() { },
  components: {},
  methods: {
    edit() {
      this.$emit("editClicked");
    },
    markedAsReviewed() {
      this.$emit("markReview", this.prRequestId);
    }
  },
};
</script>
<style scoped>
.submit-btn {
  height: 3.3rem;
  padding: 0 1rem;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  background-color: #EFF3FF;
  border: 1px solid #869ED8;
  color: #274BA7;
}

.submit-btn:focus {
  background-color: #EFF3FF;
  border: 1px solid #869ED8;
}

.gray-box-label {
  background: none !important;
}

.principle-select {
  height: 4.4rem;
  border-color: #eeeeee;
  background: #F8F8F8;
}

.select-text {
  font-size: 1.166rem !important;
  font-family: "BarlowR", sans-serif !important;
}

.action-desc {
  border: #eeeeee 1px solid;
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
  color: #313131;
  resize: none;
}

.gray-box-inner-row2 {
  margin-top: 2rem;
}

.lable-text1 {
  position: absolute;
  /* background: rgb(243, 240, 240); */
  background: #E5e5e5;
  margin: -0.8rem 0 0 1rem;
  padding-right: 5px;
  padding-left: 5px;
}
</style>