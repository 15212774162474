<template>
  <div class="col-12">
    <div class="row">
      <label style="margin:1rem; float:right;font-size: 1.5rem;height: 3rem;"
        class="page-heading-dropdown">Original</label>

    </div>
    <div class="gray-box-inner-row2">
      <label for="changeTypeOgReview" class="form-label page-heading-dropdown lable-text1 gray-box-label">
        Change Type
      </label>
      <b-input class="
          inputMarginTop
          form-control
          page-content-regular
          principle-select
          select-text
        " id="changeTypeOgReview" v-model="ogChangeType" disabled>
      </b-input>
    </div>
    <div class="gray-box-inner-row2">
      <label for="descriptionOgReview" class="form-label page-heading-dropdown lable-text1 gray-box-label">
        Description
      </label>
      <textarea class="page-content-regular action-desc select-text" rows="4" cols="105" id="descriptionOgReview"
        v-model="ogDescription" disabled>
      </textarea>
    </div>
    <div class="gray-box-inner-row2">
      <label for="changeTypeOgReview" class="form-label page-heading-dropdown lable-text1 gray-box-label">
        Guideline
      </label>
      <textarea class="page-content-regular action-desc select-text" rows="4" cols="105" id="changeTypeOgReview"
        v-model="ogGuideline" disabled>
      </textarea>
    </div>
  </div>
</template>
<script>
export default {
  name: "OriginalProcess",
  props: { ogChangeType: "", ogDescription: "", ogGuideline: "" },
  data() {
    return {};
  },
  mounted() { },
  components: {},
  methods: {},
};
</script>
<style scoped>
.gray-box-label {
  background: none !important;
}

.principle-select {
  height: 4.4rem;
  border-color: #eeeeee;
  background: #F8F8F8;
}

.select-text {
  font-size: 1.166rem !important;
  font-family: "BarlowR", sans-serif !important;
}

.action-desc {
  border: #eeeeee 1px solid;
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
  color: #313131;
  resize: none;
}

.gray-box-inner-row2 {
  margin-top: 2rem;
}

.lable-text1 {
  position: absolute;
  /* background: rgb(243, 240, 240); */
  background: #E5E5E5;
  margin: -0.8rem 0 0 1rem;
  padding-right: 5px;
  padding-left: 5px;
}
</style>